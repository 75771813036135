<template>
  <div class="applyJoin">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#eee',
        color: '#333',
        fontSize: '16px',
      }"
    >
      <el-table-column prop="name" label="姓名" width="300" />
      <el-table-column prop="phone" label="联系方式" />
    </el-table>
    <div class="pagination">
      <el-pagination
        :page-size="12"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="form.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      form: {
        type: 2,
        page: 1,
        limit: 12,
      },
      total: 0,
    }
  },
  methods: {
    handleCurrentChange(page) {
      this.form.page = page
      this.getList()
    },
    getList() {
      this.$request.getShyUserList(this.form, (res) => {
        console.log(res.data)
        this.tableData = res.data
        this.total = res.total
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.applyJoin {
  padding: 0 32px;
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
